import React from "react";

import image from "../../assets/capabilities.jpg";
import { ReactComponent as Check } from "../../assets/tick.svg";
import Dots from "../dots-container/dots-container.component";
import "./home-content.styles.scss";

const HomeContent = () => {
  return (
    <div className="home-content-component">
      <div className="background"></div>
      <div className="details">
        <div className="dots-top">
          <Dots count={4} top />
        </div>
        <h1>OVERVIEW</h1>
        <div className="dots-bottom">
          <Dots count={4} top />
        </div>
      </div>

      <div className="checklist-container">
        <div className="checklist-1">
          <div className="checklist">
            <div className="icon">
              <Check />
            </div>
            <div>
              As part of the
              <span
                onClick={() =>
                  window.open("https://www.3einfrastructure.com/", "_blank")
                }
              >
                &nbsp;3E&nbsp;
              </span>
              family of companies, 1155iS leverages systems and best practices
              to ensure its processes are efficient.
            </div>
          </div>
          <div className="checklist">
            <div className="icon">
              <Check />
            </div>
            1155iS makes efforts to partner with community organizations and
            recruits workforce from those areas when possible
          </div>
        </div>
        <div className="checklist-2">
          <div className="checklist">
            <div className="icon">
              <Check />
            </div>
            <div>
              Safety and Quality are Core Values at 1155iS whereby all the
              services provided are done so to meet client and
              <span
                onClick={() =>
                  window.open("https://www.3einfrastructure.com/", "_blank")
                }
              >
                &nbsp;3E&nbsp;
              </span>
              requirements.
            </div>
          </div>

          <div className="checklist">
            <div className="icon">
              <Check />
            </div>
            1155iS is a Union Contractor and will be aligning itself with Locals
            in the region for labor and specialized operators for its projects.
          </div>
        </div>
      </div>

      <div className="bar"></div>
      <div className="specs">
        <div className="spec-container">
          <div className="spec-content">
            <h3>Capabilities</h3>
            <div className="description">
              <div className="desc-container">
                <span>01</span>
                <div>
                  <b>Distribution Construction</b>
                  <ul>
                    <li>Polyethylene (PE) New Business and Replacement</li>
                    <li>Steel Pipe New Business and Replacement</li>
                    <li>Joint Trench</li>
                    <li>Gas Conversions, Locating, Verifications</li>
                    <li>Relocations</li>
                    <li>Insertions</li>
                    <li>Horizontal Directional Drilling</li>
                  </ul>
                </div>
              </div>
              <div className="desc-container">
                <span>02</span>
                <div>
                  <b>Facilities Construction Stations:</b>
                  <ul>
                    <li>Gate Stations</li>
                    <li>Regulator Stations</li>
                    <li>Meter Protection</li>
                  </ul>
                </div>
              </div>
              <div className="desc-container">
                <span>03</span>
                <div>
                  <b>Pipeline Integrity:</b>
                  <ul>
                    <li>Integrity Dig Programs</li>
                    <li>Anomaly Digs Inspections and Repairs</li>
                    <li>CP/AC Mitigation</li>
                    <li>Validation Digs</li>
                  </ul>
                </div>
              </div>
              <div className="desc-container">
                <span>04</span>
                <div>
                  <b>General Contractor</b>
                  <ul>
                    <li>Site Preparation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="spec-image">
            <div className="dots-top">
              <Dots count={10} />
            </div>
            <div className="image">
              <img loading="lazy" src={image} alt="" />
            </div>
            <div className="dots-bottom">
              <Dots count={10} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
